import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import "../styles/slide.css";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const DocPage = () => (
  <Layout>
    <Seo title={"Welcome to Dreamland Documentary - ZHU Music"} />
    <section className="h-screen relative bg-black flex justify-center items-start">
      <StaticImage
        src={"../images/road-sign-_VIT0003-.jpg"}
        className="absolute inset-0 object-cover mx-auto"
      />
      <div className="absolute inset-0 overflow-hidden flex justify-center items-center">
        <div className="relative h-screen fixed-width perspective">
          <div className="absolute bg-gray-900 bg-dots flex items-center sign">
            <div
              style={{ marginTop: "0%", maxWidth: "100vw" }}
              className="font-display uppercase text-yellow text-center w-full h-full leading-tight led-shadow parsed-content"
            >
              <div className={`h-full led-sm`}>
                <div className="h-full flex flex-col items-center justify-center">
                  <iframe
                    class="w-full h-full"
                    width="100%"
                    height="100%"
                    src="https://www.youtube-nocookie.com/embed/ZM3BfCeyFpU"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-4 right-4 uppercase text-xs">
          <ul>
            <li className={`mt-1 text-right`}>
              <Link
                href="/"
                className="text-white opacity-40 hover:opacity-100 transition"
              >
                Home
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </Layout>
);

export default DocPage;
